var SL9 = SL9 || {};

(function($) {



  // Core functions
  SL9.main = {

    init: function() {
      // Kickoff
      this.config();
      this.bindEvents();
    },

    /**
		 * Define vars for caching.
		 */
		config : function() {

			this.config = {

				// General
				$window                 : $( window ),
				$document               : $( document ),
				$head                   : $( 'head' ),
				$body                   : $( 'body' ),
        $themeURL               : sl9_wpex.themePath,
        $windowPosition         : $( window ).scrollTop(),
        $formSelects            : $('.gfield_select, .wpcf7-form select')
      }
    },

        /**
		 * Bind Events.
		 */
		bindEvents : function() {
			var self = this;

      /*** Run on Document Ready ***/
			$(function() {
        self.insertExtras();
      });

      /*** Run on Window Load ***/
      self.config.$window.on( 'load', function() {

        // Select elements selected
        self.config.$formSelects.on('load', self.selectElSelected);

      });

      /*** Run on Window Resize ***/
			self.config.$window.resize( function() {
      });

      /*** Run on Window Scroll ***/
			self.config.$window.scroll( function() {
      });

      /*** Run on Orientation Change ***/
			self.config.$window.on( 'orientationchange', function() {
      });

      /*** Adjust select element classes */
      self.config.$formSelects.on('change', self.selectElSelected);

      /** Be sure to run after validation/ajax, too */
      self.config.$document.on('gform_page_loaded', function(event, form_id, current_page) {
        var func = self.selectElSelected;

        self.config.$formSelects.on('load', func);
      });      
    },

    getSVG: async function(svgURL, args) {
      const result = await $.ajax({
        url: svgURL,
        type: 'GET',
        dataType: 'html',
        data: args
      });
      return result;
    },

    insertExtras: function() {
      var self = this;

      // Do stuff to insert extra content via js
    },

    browserName: function() {
      // Regex useragent string pattern https://gist.github.com/ticky/3909462
      var pattern = new RegExp(/(MSIE|Trident|(?!Gecko.+)Firefox|(?!AppleWebKit.+Chrome.+)Safari(?!.+Edge)|(?!AppleWebKit.+)Chrome(?!.+Edge)|(?!AppleWebKit.+Chrome.+Safari.+)Edge|AppleWebKit(?!.+Chrome|.+Safari)|Gecko(?!.+Firefox))(?: |\/)([\d\.apre]+)/),
        check = navigator.userAgent.match(pattern);

        if ( check && check[1].length ) {
          return check[1];
        } else {
          return '';
        }
    },

    scrollUpClass: function() {
      var self = this,
          scroll = this.config.$window.scrollTop();

      if ( scroll > this.config.$windowPosition && scroll !== 0 ) {
        // Scrolling Down
        self.config.$body.removeClass('scrolling-up');
      } else {
        // Scrolling up
        self.config.$body.addClass('scrolling-up');
      }
      this.config.$windowPosition = scroll;
    },

    selectElSelected: function() {
      var el = $(this);

      if ( !el.val() ) {
        $(this).removeClass('dropdown--checked');

      } else {
        $(this).addClass('dropdown--checked');
      }

      

    }

  }

  SL9.main.init();

})(jQuery);
